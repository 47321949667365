import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { useTranslation } from 'gatsby-plugin-react-i18next'

const pageStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}

const paragraphStyles = {
    marginBottom: 48,
}



const NotFoundPage = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <Navbar  />
            <main style={pageStyles} >
                <br />
                <h1 style={headingStyles}>404 - Page not found</h1>
                <p style={paragraphStyles}>
                    {t("Die Seite existiert leider nicht.")}
                    <br />
                    <Link to="/"> &#187; Home </Link>.
                </p>
            </main>
            <Footer />
        </Layout>
    )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>

//export const query = graphql`
//  query($language: String!) {
//    locales: allLocale(filter: {language: {eq: $language}}) {
//      edges {
//        node {
//          ns
//          data
//          language
//        }
//      }
//    }
//  }
//`;
